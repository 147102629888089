import React, { useEffect, useState } from 'react'
import axios from "axios";
import wordsToNumbers from "words-to-numbers";
import HTMLReactParser from "html-react-parser";
import ShowMoreText from 'react-show-more-text';
import ReactTimeAgo from 'react-time-ago'
import  './TeamDetailReview.scss';
import { Container, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import googleImg from '../../images/home/google-reviews-2.svg'
import { isMobileOnly } from 'react-device-detect';

const TeamDetailReview = (props) => {

    const [testimonials, setTestimonials] = useState([]);
    const [overallReviews, setOverallReviews] = useState([]);

    const strapiconfig = {
        headers: {
          Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }

    let peopleName = props.people_name
    let firstName;
    if (typeof peopleName === 'string') {
        firstName = peopleName.split(' ')
        if(firstName.length > 1){
            firstName = firstName.slice(0, -1)[0].trim().toLocaleLowerCase()
        }
        if (firstName === "nice") {
            firstName = peopleName.trim().toLocaleLowerCase()
        } else if (peopleName.trim() === "Ahmed Soufan") {
            firstName = peopleName.trim().toLocaleLowerCase()
        } else {
            firstName = peopleName.split(' ')
            if(firstName.length > 1){
                firstName = firstName.slice(0, -1)[0].trim().toLocaleLowerCase()
            }
        }
    }
    // console.log("firstName====", firstName)
    useEffect(() => {
        let url = `${process.env.GATSBY_STRAPI_SRC.replace('-dev', '')}/stb-google-reviews/google-review-reviews`
        getitems(url);
    }, [])

    var ggl_reviews = [];
    const getitems = async url => {
    try {
        const reviewsData = await axios.get(url, strapiconfig) // could be from env files
        // console.log("reviewsData===", reviewsData)
        setOverallReviews(reviewsData.data)
        if (reviewsData.data && reviewsData.data.length > 0) {
        reviewsData.data.map((item, index) => {
            var star_no = wordsToNumbers(item.starRating);
            if (star_no > 3) {
                let strTitle = item.comment;
                let Name;
                if (typeof strTitle === 'string') {
                    Name = strTitle.toLocaleLowerCase().includes(firstName)
                }
            if (Name == true) {
                ggl_reviews.push(item);
            }
            }

        })
        }
        setTestimonials(ggl_reviews);
    } catch (error) {
        // cache it if fail/error;
        console.error(error)
    }
    }

    const starDiv = (star_text) => {
        var star_no = wordsToNumbers(star_text);
        var star_div = '';
        var star_max_count = 5;
        for(var i = 0; i < star_max_count; i++){
        if(i <= star_no){
            star_div+='<i class="icon-star"></i>';
        } else{
            star_div+='<i class="icon-star-grey"></i>';
        }
        }
        return star_div;
    }


    var settings = {
        dots: true,
        arrows: false,
        infinite: testimonials.length > 3 ? true : false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        mobileFirst: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              dots: true,
              infinite: testimonials.length > 1 ? true : false,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              dots: true,
              infinite: testimonials.length > 2 ? true : false,
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              dots: true,
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 2800,
            settings: {
              dots: true,
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
      };

      var testimonial_count = overallReviews ? overallReviews.length : 0;
      var rating_count = 0;
      overallReviews && overallReviews.length > 0 && overallReviews.map((item, ind)=>{
          rating_count += parseInt(wordsToNumbers(item.starRating));
      })
      var rating_avg = rating_count/testimonial_count;

  return (
    <>
        {testimonials.length > 0 && 
        <Container>
        <div className={testimonials.length < 3 ? 'team-detail-review card_align' : 'team-detail-review'}>
            <div className='team-detail-heading'>
                <div className="team-detail-list col-lg-7 col-md-12 p-0">
                    <h2>My Customer Reviews</h2>
                </div>
                <div className="write-review top-button">
                    <a href="https://www.google.com/search?q=the+pearl+gates&oq=the+pearl+gates&aqs=chrome..69i57j46i175i199i512j0i512l3j69i61l3.12580j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3e45c571c8066b59:0x2341510a6154be50,3" className="btn btn-number btn-outline" target="_blank"><span>Leave a review</span></a>
                </div>
            </div>
            <div className="review-wrap">
                <div className="img-wrap">
                    <img src={googleImg} alt="google" />
                </div>
                <span className="text-wrap">                       
                   <strong>{parseFloat(rating_avg).toFixed(1)}/5 Rating </strong><span>from {parseInt(testimonial_count)-1}+ reviews</span>
                </span>
            </div>
            <div className="write-review bottom-button">
                    <a href="https://www.google.com/search?q=the+pearl+gates&oq=the+pearl+gates&aqs=chrome..69i57j46i175i199i512j0i512l3j69i61l3.12580j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3e45c571c8066b59:0x2341510a6154be50" className="btn btn-number btn-outline" target="_blank"><span>Leave a review</span></a>
                </div>
            <div className="team-detail-item">
                <Slider {...settings}>
                    {testimonials.map((item, index) => {
                        var star_div = starDiv(item.starRating); 
                        return(
                            <div className="team-detail-wrap" key={index}>
                                <div className="team-detail">
                                    <div className="rating-row"> 
                                        {HTMLReactParser(star_div)}
                                    </div>
                                    <p className= "more">
                                        <ShowMoreText
                                            lines={isMobileOnly ? 5 : 4}
                                            more='Show more'
                                            less='Show less'
                                            className='content-css'
                                            anchorClass='btn-more review-more'
                                            expanded={false}
                                            width={0}
                                        >
                                        {item.comment}
                                        </ShowMoreText>
                                    </p>
                                    <div className="team-detail-info">
                                        <div className="person-img">
                                            <img src={item.reviewer.profilePhotoUrl} alt=""/>
                                        </div>
                                        <div className="test-name-block">
                                            <strong className="person-name">{item.reviewer.displayName}</strong>
                                            <span className="team-detail-time">
                                                <ReactTimeAgo date={item.createTime} locale="en-US"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    )}
                </Slider>
            </div>

        </div>
            </Container>}
    </>
    )
}

export default TeamDetailReview;
