import React,{useEffect, useState, useRef} from "react"
import { Link } from "@StarberryUtils";
import Modal from "react-bootstrap/Modal"
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap';
import PlayVideo from "../Play/PlayVideo";
import ScrollAnimation from 'react-animate-on-scroll';
import HTMLParser from 'html-react-parser';
import {ImageModule} from "../../modules/Image_Module";
// images
import MemberImgLarge from "../../images/static/member-desktop.jpg"
import MemberImgMedium from "../../images/static/member-medium.jpg"
import MemberImg from "../../images/static/member-img.jpg"

import Calendly from "../../modules/calendly-link";
import {youtube_parser} from "../common/utils";

import MemberForm from "../../components/forms/member-form"

import  './MemberBlock.scss';

import bgCurve2 from "../../images/home/pg-logo-curve-2.png"
import ReactPlayer from 'react-player'

const MemberBlock =(props) => {
    const [isPlay,setPlay] = useState(false);
    const [isOpen,setIsOpen] = useState(false);

    const [isPlay2,setPlay2] = useState(true);

    
    var data = props.data;

    var youtube_url = data.Embed_Video_URL && youtube_parser(data.Embed_Video_URL);
    var calendly_link = data.Calendly_Link;

    var reviews_count = data.Customer_Reviews, rating = 0;

    var reviews_sum = reviews_count.length > 0 && reviews_count.map((item) => rating += parseFloat(item.Rating));

    var reviews_rating = rating > 0 ? parseFloat(rating / reviews_count.length) : 0;

    //console.log('youtube_url',youtube_url);

    let processedImages = JSON.stringify({});
    if (data && data.imagetransforms && data.imagetransforms.Image_Transforms) {
        processedImages = data.imagetransforms.Image_Transforms;
    }

    //console.log("Image===", data.Image && data.Image.url.split('.').pop())

    var get_url_ext = data.Image && data.Image.url.split('.').pop();
    let WhatsappText = `Hi The Pearl Gates, I would like to contact ${data.Name}`
    return(
    <div className="member-block">
        <span className="bg-curve"><img src={bgCurve2} alt="bg-img" /></span>
        <Container>
            <Row className="pb-5"> 
            <Col lg={5} className="order-lg-2 ml-lg-auto">
             <div className="animated"> 
                
                    {data.Image ? (
                        get_url_ext === "mp4" ? (
                            <div className="member-block-video">
                                <ReactPlayer className="react_player" url={data.Image.url} modestbranding="1" controls={false} autoplay={true} playsinline={true} loop={true} playing={true} muted={true} width='100%' height='100%' />
                            </div>
                        ) : 
                        <div className="member-block-video img_cnt img-zoom">
                            <ImageModule ImageSrc={data.Image} imagename={""} article_id={data.id} imagetransforms={processedImages} />
                        </div>
                    ): <span className="noImg"></span> 
                    }

                    {/* {
                        calendly_link ? (
                        <Calendly url={ calendly_link }/>
                        ) : youtube_url ? (
                        <span class="btn-play" onClick={e => {setPlay(true)}}><i class="icon-play"></i></span>
                        ) : ('')
                    } */}
                
             </div>
            </Col>
            <Col lg={7} className="order-lg-1 ">
             <div className="animated" delay={200}>
            <div className="member-block-info">
                {/* <h2>{data.Name}</h2> */}
                <h1>{data.Name}</h1>
                {data.Designation && <p>{data.Designation}</p> }
                {data.Areas_of_Speciality && <p><strong>Areas of Speciality:</strong> {data.Areas_of_Speciality}</p> }

                {/* {
                    reviews_count.length > 0 && (
                        <div className="breadcrumb-block-office">
                            <div className="rating-row">
                            <i className="icon-star-grey"><i className="icon-star" style={{width:reviews_rating*20+'%'}}></i></i> 
                            </div>
                            <p className="rating-line">Rated <strong>{reviews_rating}/5</strong> from <a href="javascript:;" onClick={props.handleClick}>{reviews_count.length} Reviews</a></p>
                        </div>
                    )
                } */}
                
                <div className="block-btn-group">
                    <a href={`javascript:;`} onClick={() => setIsOpen(true)} className="btn btn-primary"><span>Contact {data.Name && data.Name.split(' ')[0]}</span></a>
                    
                    {data?.WhatsApp && <a href={`tel:+${data.WhatsApp}`} className="btn btn-number btn-outline"><span>Call +{data.WhatsApp}</span></a>}

                    {data?.Linked_In && <a href={data.Linked_In} target={"_blank"} className="btn btn-link btn-outline btn-linkedin" rel="me"> <span><i className="icon-linkedin"></i></span></a> }

                    {data?.WhatsApp && <a href={`https://api.whatsapp.com/send?text=${WhatsappText}&phone=${data.WhatsApp}`} target={"_blank"} className="btn btn-link btn-outline"> <span><i className="icon-whatsapp"></i></span></a> }
                </div>
                <div className="member-block-content">
                    {data?.Highlight_Text && <p className="highlight-text">{data.Highlight_Text}</p>}

                    {data?.About && HTMLParser(data.About)}
                    
                </div>
            </div>
             </div>
            </Col>
            </Row>
            <PlayVideo
                isOpen = { isPlay } 
                isCloseFunction = { setPlay }
                videoId={youtube_url}
                isAutoPlay={1}
            />
        </Container>

        <Modal
            id="valuation_modal"
            show={isOpen}
            onHide={() => setIsOpen(false)}
            backdrop="static"
            keyboard={false}
            className="member-contact-modal"
          >
            {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}
            <Modal.Header closeButton className="contact-close-btn memberPopup">
              <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Contact - {data.Name}</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <MemberForm to_email_id={data.Email} formName={`Individual Contact`} formLabel={`Contact ${data.Name}`} />
            </Modal.Body>
        </Modal>
        
    </div>
    )
}

export default MemberBlock;

